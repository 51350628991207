/* eslint-disable no-shadow, no-use-before-define */
import React, { useMemo, useState } from 'react';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import { FunctionField, SelectField, TextField, useRefresh, useTranslate } from 'react-admin';
import { useDispatch, useSelector } from 'react-redux';
import { CloudDownloadOutlined, RemoveCircle } from '@material-ui/icons';
import { keyBy } from 'lodash';
import { useParams } from 'react-router-dom';
import { CAMPAIGN_STATUS, PLAYER_BATCH_SOURCE_TYPE, PLAYER_BATCH_STATUS } from '../../../../../constant/campaign';
import { showConfirmDialog } from '../../../../../services/redux/app/app.actions';
import { useRemoveCampaignPlayer } from '../../utils';
import { transformIdValue } from '../../../../../services/util/stringUtil';
import PlayerBatchDetailsDrawer from './PlayerBatchDetailsDrawer';
import { hasPermission, isSystemAdmin } from '../../../../../services/util/auth';
import resourceSlug from '../../../../../constant/resource-slug';
import CustomizedList from '../../../../../base/components/customized/CustomizedList';
import PlayerUpdateBatchPagination from './PlayerUpdateBatchPagination';
import PlayerUpdateBatchAction from './PlayerUpdateBatchActions';
import { DateField } from '../../../../../base/components/ra/fields';
import PlayerUpdateBatchFilterWrapper from './PlayerUpdateBatchFilters';
import { useAuthUser } from '../../../../../base/hooks';

const useStyles = makeStyles(theme => ({
  table: {
    padding: '0px 4px',
  },
  withdrawButton: {
    width: 36,
    height: 36,
    '&,&:hover': {
      color: theme.palette.error.main,
      boxShadow: 'none',
    },
  },
  downloadButton: {
    width: 36,
    height: 36,
    '&,&:hover': {
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
  actionColumn: {
    background: 'white',
  },
  row: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

const PlayerUpdateBatchTable = ({ campaign }) => {
  const dispatch = useDispatch();
  const t = useTranslate();
  const classes = useStyles();
  const [batchDetails, setBatchDetails] = useState();
  const { id } = useParams();
  const refresh = useRefresh();
  const isSA = isSystemAdmin();
  const user = useAuthUser();
  const isOperator = !!user?.group?.id;
  const hasEditPermission = hasPermission(resourceSlug.MKT_FREE_SPINS, 'update');
  const isAuthorizedOperator = isOperator && !!campaign?.operatorUpdateEnable;

  const canUpdateBatches = campaign?.status !== CAMPAIGN_STATUS.EXPIRED && campaign?.status !== CAMPAIGN_STATUS.CANCELED
  && hasEditPermission
  && (isSA || isAuthorizedOperator);

  const [
    playerBatchList, currentPlayerBatchPage,
  ] = useSelector(({ campaign }) => [campaign.playerBatchList, campaign.currentPlayerBatchPage]);

  const batches = useMemo(() => playerBatchList[currentPlayerBatchPage]?.data || [], [playerBatchList, currentPlayerBatchPage]);

  const {
    removePlayer, isRemoving,
  } = useRemoveCampaignPlayer(id);

  const onWithdrawConfirm = batch => {
    dispatch(
      showConfirmDialog({
        isOpen: true,
        title: 'resources.campaign.cancel',
        content: 'resources.campaign.cancel-batch-desc',
        onConfirm: () => onWithdraw(batch),
      }),
    );
  };

  const onWithdraw = async batch => {
    removePlayer(null, batch.id, {
      onSuccess: () => {
        refresh();
      },
    });
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: 12,
        pt: 1.5,
      }}
    >
      {!!batchDetails && (
        <PlayerBatchDetailsDrawer
          batch={batchDetails}
          onClose={() => setBatchDetails()}
        />
      )}
      <CustomizedList
        syncWithLocation={false}
        hasList
        resource={`${resourceSlug.MKT_FREE_SPINS}/${id}/player-batch`}
        basePath={`/${resourceSlug.MKT_FREE_SPINS}/${id}/player-batch`}
        hasCreate={false}
        hasEdit={false}
        hasShow={false}
        exporter={false}
        controllerProps={{
          data: keyBy(batches, 'id'),
          ids: batches?.map(({ id }) => id),
        }}
        sort={{
          field: 'issuedDate',
          order: 'DESC',
        }}
        pagination={<PlayerUpdateBatchPagination />}
        filters={<PlayerUpdateBatchFilterWrapper />}
        actions={<PlayerUpdateBatchAction />}
        rowClick={id => setBatchDetails(keyBy(batches, 'id')[id])}
      >
        <FunctionField
          label="resources.campaign.fields.player-batch-code"
          render={record => transformIdValue(record.batchCode, {
            prefix: '',
          })}
        />
        <DateField
          source="created"
          label="resources.campaign.fields.created-date"
          sortable={false}
        />
        <TextField
          source="actor"
          label="resources.campaign.fields.created-by"
          sortable={false}
        />
        <SelectField
          source="source"
          label="resources.campaign.fields.source-type"
          choices={Object.values(PLAYER_BATCH_SOURCE_TYPE).map(source => ({
            id: source,
            name: t(`resources.campaign.player-batch-source-type.${source}`),
          }))}
          sortable={false}
        />
        <DateField
          source="issuedDate"
          label="resources.campaign.fields.scheduled-date"
          sortable={false}
        />
        <SelectField
          source="status"
          label="resources.campaign.fields.status"
          choices={Object.values(PLAYER_BATCH_STATUS).map(status => ({
            id: status,
            name: t(`resources.campaign.player-batch-status.${status}`),
          }))}
          sortable={false}
        />
        <FunctionField
          label="ra.field.action"
          render={batch => (
            <Box
              display="flex"
              justifyContent="center"
              sx={{
                gap: 4,
              }}
            >
              {canUpdateBatches && (
                <Tooltip title={t('resources.campaign.cancel')}>
                  <Box>
                    <IconButton
                      className={classes.withdrawButton}
                      disabled={batch.status !== PLAYER_BATCH_STATUS.SCHEDULED || isRemoving}
                      onClick={e => {
                        e.stopPropagation();
                        onWithdrawConfirm(batch);
                      }}
                    >
                      <RemoveCircle />
                    </IconButton>
                  </Box>
                </Tooltip>
              )}
              <Tooltip title={t('resources.campaign.download-player-batch')}>
                <Box>
                  <IconButton
                    disableRipple
                    className={classes.downloadButton}
                    href={`/api/${resourceSlug.MKT_FREE_SPINS}/${campaign?.id}/download-players-in-batch?playerBatchId=${batch.id}`}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                  >
                    <CloudDownloadOutlined />
                  </IconButton>
                </Box>
              </Tooltip>
            </Box>
          )}
        />
      </CustomizedList>
    </Box>
  );
};

export default PlayerUpdateBatchTable;
